import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "mainPage",
        component: () => import(/* webpackChunkName: "landingPage" */"../views/LandingPage/MainPage.vue"),
    }, {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/Login.vue"),
    }, {
        path: "/registration",
        name: "registration",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/Registration.vue"),
    }, {
        path: "/registration-success",
        name: "registrationSuccess",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/RegistrationSuccess.vue"),
    }, {
        path: "/registration-confirmation/:securityCode",
        name: "registrationConfirmation",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/RegistrationConfirmation.vue"),
        props: true,
    }, {
        path: "/forgotten-password",
        name: "forgottenPassword",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/ForgottenPassword.vue"),
    },
    {
        path: "/new-password-confirmation/:securityCode",
        name: "newPasswordConfirmation",
        component: () => import(/* webpackChunkName: "authFunctions" */"../views/Auth/NewPasswordConfirmation.vue"),
        props: true,
    },
    {
        path: "/courses",
        name: "courses",
        component: () => import(/* webpackChunkName: "courses" */"../views/Courses/Courses.vue"),
    },
    {
        path: "/join-course",
        name: "joinCourse",
        component: () => import(/* webpackChunkName: "courses" */"../views/Courses/JoinCourse.vue"),
    },
    {
        path: "/change-course",
        name: "changeCourse",
        component: () => import(/* webpackChunkName: "courses" */"../views/Courses/ChangeCourse.vue"),
    },
    {
        path: "/my-company",
        name: "myCompany",
        component: () => import(/* webpackChunkName: "myCompany" */"../views/MyCompany/MyCompany.vue"),
    },
    {
        path: "/my-company/create",
        name: "myCompanyCreate",
        component: () => import(/* webpackChunkName: "myCompany" */"../views/MyCompany/CreateCompany.vue"),
    },
    {
        path: "/my-company/invite",
        name: "myCompanyInvite",
        component: () => import(/* webpackChunkName: "myCompany" */"../views/MyCompany/Invite.vue"),
    },
    {
        path: "/courses/:courseId",
        name: "course",
        component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/Course.vue"),
        props: true,
        children: [
            {
                path: "/courses/:courseId/dashboard",
                name: "courseDashBoard",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/Dashboard.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/setup-form",
                name: "courseSetupForm",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/SetupForm.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/setup-upload",
                name: "courseSetupUpload",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/SetupUpload.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/copy-setup",
                name: "courseCopySetup",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/CopySetup.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/basic-data-form",
                name: "basicDataForm",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/BasicDataForm"),
                props: true,
            },
            {
                path: "/courses/:courseId/new-company",
                name: "courseNewCompany",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/NewCompany.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/edit-company/:companyId",
                name: "courseEditCompany",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/EditCompany.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/new-user",
                name: "courseNewUser",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/NewUser.vue"),
                props: true,
            },
            {
                path: "/courses/:courseId/move-user/:tempUserId",
                name: "courseMoveUser",
                component: () => import(/* webpackChunkName: "courseAdmin" */"../views/CourseAdmin/MoveUser.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import(/* webpackChunkName: "admin" */"../views/Admin/Admin.vue"),
        children: [
            {
                path: "/admin/master-excel-upload",
                name: "masterExcelUpload",
                component: () => import(/* webpackChunkName: "admin" */"../views/Admin/MasterExcelUpload.vue"),
            },
            {
                path: "/admin/game-documents-upload",
                name: "gameDocumentsUpload",
                component: () => import(/* webpackChunkName: "admin" */"../views/Admin/GameDocumentsUpload.vue"),
            },
        ]
    },
    {
        path: "/simulation",
        name: "simulation",
        component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Simulation.vue"),
        children: [
            {
                path: "/simulation/overview",
                name: "simulationOverview",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Overview.vue"),
            },{
                path: "/simulation/map",
                name: "simulationMap",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Map/Map.vue"),
            },
            {
                path: "/simulation/stock",
                name: "simulationStock",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Stock.vue"),
            },
            {
                path: "/simulation/block-database",
                name: "simulationBlockDatabase",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/BlockDatabase.vue"),
            },
            {
                path: "/simulation/projects",
                name: "simulationProjects",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Projects.vue"),
            },
            {
                path: "/simulation/tailings",
                name: "simulationTailings",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Tailings.vue"),
            },
            {
                path: "/simulation/upgrades",
                name: "simulationUpgrades",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Upgrades.vue"),
            },
            {
                path: "/simulation/finance/profit-and-loss",
                name: "simulationProfitAndLoss",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Finance/ProfitAndLoss.vue"),
            },
            {
                path: "/simulation/finance/balance-sheet",
                name: "simulationBalanceSheet",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Finance/BalanceSheet.vue"),
            },
            {
                path: "/simulation/finance/cash-flow",
                name: "simulationCashFlow",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Finance/CashFlow.vue"),
            },
            {
                path: "/simulation/finance/roi",
                name: "simulationRoi",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Finance/ROI.vue"),
            },
            {
                path: "/simulation/statistics",
                name: "simulationStatistics",
                component: () => import(/* webpackChunkName: "simulation" */"../views/Simulation/Statistics.vue"),
            },
            {
                path: "/simulation/documents",
                name: "simulationDocuments",
                component: () => import(/* webpackChunkName: "admin" */"../views/Simulation/Documents.vue"),
            },
        ],
    },
    {
        path: "/no-permission",
        name: "noPermission",
        component: () => import(/* webpackChunkName: "slavePages" */"../views/Slave/NoPermission.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "page404",
        component: () => import(/* webpackChunkName: "slavePages" */"../views/Slave/PathNotFound.vue"),
    },
];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        let dropdown = document.querySelector("#navbarNav")
        if(dropdown){
            dropdown.classList.remove("show")
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: "active-link",
    routes,
});

export default router;
