<template>
  <div v-if="simulationData.modals.openShaft.blocks.length>0" class="modal" id="openShaftModal" tabindex="-1" aria-labelledby="openShaftModalLabel" data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="openShaftModalLabel">
            <i class="fa-duotone fa-fan"></i> Open shaft
          </h5>
        </div>
        <div class="modal-body">
          <div>
            <p>
              You will open a new shaft on the following blocks:<br>
              <span v-for="block in blocks">
              #{{ block.id}}
              </span>
            </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" @click="cancelShaft" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="openShaft" data-bs-dismiss="modal">
            <i class="fa-duotone fa-fan"></i> Open new shaft
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import {NumberFunctions} from "@/classes/number";
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "OpenShaft",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()

    return {
      nF,
      Simulation,
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.openShaft.blocks
    },
  },
  watch: {
  },
  methods: {
    openModal(e, blocks) {
      e.simulationData.modals.openShaft.blocks = blocks

      const blockData = blocks[0];
      blocks = e.simulationData.blocks.filter((block) => block.x === blockData.x && block.y === blockData.y && block.l !== blockData.l);
      blocks.forEach((block) => {
        e.simulationData.blocksForActions.push(block);
      });

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("openShaftModal"));
        modalInstance.show();
      }, 0);
    },
    cancelShaft() {
      this.Simulation.methods.removeAllBlocksFromActions(this)
    },
    openShaft(){
      this.Simulation.methods.action(this, 17, this.blocks,null)
    }
  },
};
</script>

<style scoped>

</style>