<template>
  <div class="footer px-2 py-1">
    <span v-if="this.currentPage === 'simulationMap'"  class="level float-start">Level {{ actualLevel }}</span>
    <button v-if="this.currentPage === 'simulationMap'"  class="btn btn-sm btn-primary me-2 float-start" @click="changeLevel(-1)">
      <i class="fa-duotone fa-up"></i>
    </button>
    <button v-if="this.currentPage === 'simulationMap'"  class="btn btn-sm btn-primary me-2 float-start" @click="changeLevel(1)">
      <i class="fa-duotone fa-down"></i>
    </button>
    <select v-if="this.currentPage === 'simulationMap'"  class="form-control-sm float-start me-2" v-model="selectLayer" @change="changeLayer">
      <option v-for="(layer, index) in simulationData.setup.mapLayers" :key="index" :value="layer.id">{{ layer.name }}</option>
    </select>
    <select v-if="selectLayer==5 && this.currentPage==='simulationMap'" class="form-control-sm ms-2 float-start me-2" v-model="selectAnalyzeLayer" @change="changeAnalyzeLayer">
      <option value="1_si">Si %</option>
      <option value="1_al">Al %</option>
      <option value="1_fe">Fe %</option>
      <option value="1_p">P %</option>
      <option value="1_k">K %</option>
      <option value="1_na">Na %</option>
      <option value="1_ca">Ca %</option>
      <option value="1_ti">Ti %</option>
      <option value="1_mg">Mg %</option>
      <option value="1_mn">Mn %</option>
      <option value="1_sr">Sr %</option>
      <option value="1_cr">Cr %</option>
      <option value="1_satmagan">Satmagan %</option>
      <option value="2_actinolitePc">act %</option>
      <option value="2_albitePc">alb %</option>
      <option value="2_apatitePc">apa %</option>
      <option value="2_biotitePc">bio %</option>
      <option value="2_epidotePc">epi %</option>
      <option value="2_hematitePc">hem %</option>
      <option value="2_magnetitePc">mag %</option>
      <option value="2_grainSizeMicrons">grain size</option>
      <option value="2_specificGravity">specific gravity</option>
    </select>
    <select v-if="selectLayer>=6 && selectLayer<=13 && this.currentPage==='simulationMap'" class="form-control-sm ms-2 float-start" v-model="selectTestLayer" @change="changeTestLayer">
      <option value="1_massPull">Laboratory test - Mass pull %</option>
      <option value="1_fePct">Laboratory test - Fe %</option>
      <option value="1_siPct">Laboratory test - Si %</option>
      <option value="1_alRecPct">Laboratory test - Al%</option>
      <option value="1_pPct">Laboratory test - P%</option>
      <option value="2_massPull">Pilot test - Mass pull %</option>
      <option value="2_fePct">Pilot test - Fe %</option>
      <option value="2_siPct">Pilot test - Si %</option>
      <option value="2_alRecPct">Pilot test - Al%</option>
      <option value="2_pPct">Pilot test - P%</option>
    </select>
    <div class="float-end">
      <button class="btn btn-sm btn-danger reset-btn float-start me-2" v-if="permissionLevel===90 && myCompany.isTest===true" @click="Simulation.methods.reset(this)">
        <i class="fa-duotone fa-trash-undo"></i> <span>Reset test team</span>
      </button>
      <select class="form-control-sm float-start me-2" v-model="selectTeam" @change="changeTeam" v-if="permissionLevel===90 && teams!==null">
        <option v-for="(team, index) in teams" :key="index" :value="team.companyId">{{ team.companyName }}</option>
      </select>
      <button class="btn btn-sm btn-primary float-start me-1" type="button" :disabled="actualDay<=1" @click="changeDay(-1)">
        <i class="fa-duotone fa-backward"></i>
      </button>
      <input type="text" class="form-control form-control-sm float-start me-1" v-model="selectDay" style="max-width: 60px" @change="changeDay('input')">
      <button class="btn btn-sm btn-primary float-start me-2" type="button" :disabled="actualDay==realActualDay"  @click="changeDay(1)">
        <i class="fa-duotone fa-forward"></i>
      </button>
      <button class="btn btn-sm btn-secondary float-start" type="button" :disabled="actualDay==realActualDay" @click="changeDay('now')">
        <i class="fa-duotone fa-calendar-day"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Simulation from "@/views/Simulation/Simulation.vue";
import Map from "@/views/Simulation/Map/Map.vue";

export default {
  name: "Footer",
  components: { Simulation, Map },
  props: ["initData", "contents", "simulationData"],
  data() {
    const { blocks } = this.simulationData;
    const { setup } = this.simulationData;
    const { map } = setup;
    const { x } = map;
    const { y } = map;
    const permissionLevel = this.initData.user.permissionLevel
    const myCompany = this.initData.user.myCompany

    let actualLayer = localStorage.getItem('vMine_'+this.initData.user.selectedCourseId+'_actualLayer')
    if(actualLayer===null){
      actualLayer = setup.actualLayer
    }else{
      setup.actualLayer = parseInt(actualLayer)
    }

    return {
      blocks,
      x,
      y,
      selectLayer: actualLayer,
      selectAnalyzeLayer: setup.selectAnalyzeLayer,
      selectTestLayer: setup.selectTestLayer,
      selectTeam: null,
      selectDay: null,
      permissionLevel,
      myCompany,
      Simulation,
    }
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    teams() {
      return this.simulationData.setup.teams;
    },
    teamId() {
      let teamId = this.simulationData.setup.teamId
      if(teamId!==null){
        this.selectTeam = teamId
      }
      return teamId
    },
    actualLevel() {
      return this.simulationData.setup.actualLevel
    },
    actualDay() {
      let actualDay = this.simulationData.setup.actualDay
      if(actualDay!==null){
        this.selectDay = actualDay
      }
      return actualDay;
    },
    realActualDay() {
      return this.simulationData.setup.realActualDay
    },
    isTransitionInProgress(){
      return this.simulationData.setup.map.isTransitionInProgress;
    },
    mapAnimationReverse(){
      return this.simulationData.setup.map.mapAnimationReverse;
    },
    mapTransitionShow(){
      return this.simulationData.setup.map.mapTransitionShow;
    },
  },
  watch: {
    teamId(){},
    actualDay(){},
  },
  methods: {
    changeLevel(level) {
      if (this.isTransitionInProgress) {
        return;
      }

      if ((level === -1 && this.actualLevel === 1) || (level === 1 && this.actualLevel === 10)) {
        return;
      }

      this.simulationData.setup.map.isTransitionInProgress = true

      this.simulationData.setup.map.mapAnimationReverse = level < 0;
      this.simulationData.setup.map.mapTransitionShow = false


      setTimeout(() => {
        this.simulationData.setup.actualLevel += level
        this.simulationData.setup.map.mapTransitionShow = true
        this.simulationData.setup.map.isTransitionInProgress = false
        //STORE
        localStorage.setItem('vMine_' + this.initData.user.selectedCourseId + '_actualLevel', this.simulationData.setup.actualLevel)
      }, 400);
    },
    changeLayer() {
      localStorage.setItem('vMine_' + this.initData.user.selectedCourseId + '_actualLayer', this.selectLayer)
      this.simulationData.setup.actualLayer = this.selectLayer;
    },
    changeAnalyzeLayer() {
      this.simulationData.setup.selectAnalyzeLayer = this.selectAnalyzeLayer;
    },
    changeTestLayer() {
      this.simulationData.setup.selectTestLayer = this.selectTestLayer
    },
    changeTeam() {
      //CLEAR BLOCKS FOR ACTIONS
      this.simulationData.blocksForActions = []
      localStorage.removeItem('vMine_'+this.initData.user.selectedCourseId+'_blocksForActions')
      //GET SIM DATA
      this.Simulation.methods.getSimulationData(this, this.selectTeam, null)
    },
    changeDay(type) {
      let day = this.actualDay
      if(type==='input'){
        day = this.selectDay
      }else if(type==='now'){
        day = this.realActualDay
      }else if(type===-1){
        day = this.actualDay - 1
      }else if(type===1){
        day = this.actualDay + 1
      }
      this.Simulation.methods.getSimulationData(this, this.selectTeam, day)
    }
  }
}
</script>

<style scoped>
.footer{
  background-color: rgba(255,255,255,0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.level{
  font-weight: bold;
  padding-top: 5px;
  padding-right: 0.5rem;
}
</style>