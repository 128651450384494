<template>
  <div v-if="simulationData.modals.testDrill" class="modal" id="testDrillModal" tabindex="-1" aria-labelledby="testDrillModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="testDrillModalLabel">
            <i class="fa-duotone fa-bore-hole"></i> Drill hole
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            You will drill a hole on the following {{blocks.length}} blocks:<br>
            <span v-for="block in blocks">
              #{{ block.id}}
            </span>
          </p>
          <p>
            Process time: 1 day / block<br>
            When finished with the last block: {{ blocks.length }} days<br>
            Cost per block: {{ nF.formatNumber((simulationData.setup.costs.testDrillPerBlock  * simulationData.setup.actualInflation)) }}<br>
            Sum cost: {{ nF.formatNumber((blocks.length * simulationData.setup.costs.testDrillPerBlock * simulationData.setup.actualInflation)) }}
          </p>
          <p>Are you sure to drill a hole?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="testDrill" data-bs-dismiss="modal">
            <i class="fa-duotone fa-bore-hole"></i> Drill hole
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NumberFunctions} from "@/classes/number";
import { Modal } from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "TestDrill",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()

    return {
      nF,
      Simulation
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.testDrill
    }
  },
  methods: {
    openModal(e, blocks) {
      e.simulationData.modals.testDrill = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("testDrillModal"));
        modalInstance.show();
      }, 0);
    },
    testDrill(){

      this.Simulation.methods.action(this, 10, this.blocks,null)
    }
  },
};
</script>

<style scoped>

</style>