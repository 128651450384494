export class Validate {
    constructor() {
    }

    emptyField(value, errorText) {
        if (value === null || value.length === 0) {
            return errorText;
        }
        return null;
    }

    email(value, errorText) {
        if (value === null) {
            return null;
        }
        if (value.length > 0 && value.search(new RegExp("^[\.\+_a-z0-9-]+@([0-9a-z][0-9a-z-\.]*[0-9a-z][\.])+[a-z][a-z][mtgvuzfrlpo]?[oepa]?$", "gi")) < 0) {
            return errorText;
        }
        return null;
    }

    password(value, errorText) {
        if (value === null || value.length < 7) {
            return errorText;
        }
        return null;
    }

    checkboxChecked(value, errorText) {
        if (value === false) {
            return errorText;
        }
        return null;
    }

    selected(value, errorText) {
        if (value === null || value === 0 || value==='0' || value === "") {
            return errorText;
        }
        return null;
    }

    rePassword(password, rePassword, errorText) {
        if (password !== rePassword) {
            return errorText;
        }
        return null;
    }
}