export class Response {
    constructor() {
    }

    handle(response) {
        let responseData = response.data;

        this.status = responseData.status;
        this.redirect = responseData.redirect;
        this.msg = responseData.msg;
        this.msgKey = responseData.msgKey;
        this.data = responseData.data;

        if (this.msgKey !== null && this.msgKey.substr(0, 7) === "session") {
            setTimeout(() => {
                window.location.assign("/");
            }, 3000);
        }

        if (this.status === true && this.redirect != null) {
            this.redirectTo(this.redirect);
            return false;
        } else if (this.status === true && this.msg != null) {
            responseData.msgType = "success";
            return responseData;
        } else if (this.status === false && this.msg != null && this.redirect == null) {
            responseData.msgType = "error";
            return responseData;
        } else if (this.status === false && this.msg != null && this.redirect != null) {
            this.redirectTo(this.redirect);
            responseData.msgType = "error";
            return responseData;
        }

        return responseData;
    }

    errorHandle(response) {
        let error = {
            msgType: null,
            msg: null,
        };
        let status = response.status;

        switch (status) {
            case 400 :
                error.msgType = "error";
                error.msg = "The server is not available, or you do not have an Internet connection, or the central server is not available.";
                return error;
            case 500 :
                error.msgType = "error";
                error.msg = "Server codebase error, contact the helpdesk, write exactly what you are experiencing.";
                return error;
            default:
                error.msgType = "error";
                error.msg = "Whoops, something went wrong, error code: " + status + "<br>Contact the helpdesk, write down exactly what you are experiencing.";
                return error;
        }
    }

    redirectTo(redirect) {
        window.location.assign(redirect);
    }
}