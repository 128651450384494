<template>
  <div v-if="simulationData.modals.road" class="modal" id="roadModal" tabindex="-1" aria-labelledby="roadModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="roadModalLabel">
            <i class="fa-duotone fa-road"></i> Build road
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            You will build road on the following {{blocks.length}} blocks:<br>
            <span v-for="block in blocks">
              #{{ block.id}}
            </span>
          </p>
          <p>
            Process time: {{roadProcessTime}} day / block<br>
            When finished with the last block: {{ roadProcessTime }} days<br>
            Cost per block: {{ nF.formatNumber(roadCost) }}<br>
            Sum cost: {{ nF.formatNumber(blocks.length * roadCost) }}
          </p>
          <p>Are you sure to build a road?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="road" data-bs-dismiss="modal">
            <i class="fa-duotone fa-road"></i> Build road
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NumberFunctions} from "@/classes/number";
import { Modal } from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "Road",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()

    return {
      nF,
      Simulation,
      roadProcessTime : this.simulationData.setup.processTime.createRoad,
      roadCost : this.simulationData.setup.costs.roadCreate,
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.road
    },
  },
  methods: {
    openModal(e, blocks) {
      e.simulationData.modals.road = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("roadModal"));
        modalInstance.show();
      }, 0);
    },
    road(){
      this.Simulation.methods.action(this, 14, this.blocks,null)
    }
  },
};
</script>

<style scoped>

</style>