<template>
  <div v-if="simulationData.modals.analyze" class="modal" id="analyzeModal" tabindex="-1" aria-labelledby="analyzeModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="analyzeModalLabel">
            <i class="fa-duotone fa-vial-virus"></i> Analyze
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            You will analyze the following {{blocks.length}} blocks:<br>
            <span v-for="block in blocks">
              #{{ block.id}}
            </span>
          </p>
          <p>
            Process time: {{simulationSetup.processTime.analyze}} day / block<br>
            When finished with the last block: {{simulationSetup.processTime.analyze}} days<br>
            Cost per block: {{ nF.formatNumber(getCostPerBlock) }}<br>
            Sum cost: {{ nF.formatNumber(getSumCostWithoutAnalyzed) }}
          </p>
          <div class="mb-3">
            <label>Analyze type (multiple)*</label>
            <select class="form-control" id="analyzeSelect" multiple v-model="analyzeType">
              <option v-for="analysis in simulationSetup.blockAnalysis" :value="analysis.id">{{analysis.name}}</option>
            </select>
            <div class="small text-danger" v-if="analyzeTypeError">
              {{analyzeTypeError}}
            </div>
          </div>
          <p>Are you sure analyze?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="analyze">
            <i class="fa-duotone fa-vial-virus"></i> Analyze
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NumberFunctions} from "@/classes/number";
import TomSelect from "tom-select";
import { Modal } from "bootstrap";
import Simulation from "@/views/Simulation/Simulation.vue";
import {Validate} from "@/classes/validate";

export default {
  name: "Analyze",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    let simulationSetup = this.simulationData.setup
    let tsAnalyzeSelect = null

    return {
      nF,
      simulationSetup,
      tsAnalyzeSelect,
      blockAnalysis: simulationSetup.blockAnalysis,
      analyzeType: [],
      analyzeTypeError: null,
      Simulation
    };
  },
  updated() {
    let tsAnalyzeConfig = {
      maxOptions: null,
      maxItems: this.blockAnalysis.length,
      plugins: {
        remove_button: {
          title: "Delete",
        },
      },
      hideSelected: true,
      render: {
        no_results: function (data, escape) {
          return '<div class="no-results">Nincs találat</div>';
        },
      },
    };
    if(this.tsAnalyzeSelect===null){
      this.tsAnalyzeSelect = new TomSelect("#analyzeSelect", tsAnalyzeConfig);
    }
  },
  computed: {
    blocks() {
      return this.simulationData.modals.analyze;
    },
    getCostPerBlock(){
      //GET COST FROM blockAnalysis BY analyzeType
      let cost = 0
      for(let i=0; i<this.analyzeType.length; i++){
        let analyzeType = this.analyzeType[i]
        let blockAnalysis = this.blockAnalysis.find(x => x.id === analyzeType)
        cost += blockAnalysis.cost
      }
      //INFLATION
      cost = cost * this.simulationSetup.actualInflation
      //RETURN
      return cost
    },
    getSumCostWithoutAnalyzed(){
      //GET COSTS BY ANALYZE TYPE
      let analyzeTypeCosts = []
      for(let i=0; i<this.analyzeType.length; i++){
        let analyzeType = this.analyzeType[i]
        let blockAnalysis = this.blockAnalysis.find(x => x.id === analyzeType)
        analyzeTypeCosts[analyzeType] = blockAnalysis.cost
      }

      let blocks = this.blocks
      let sum = 0
      for(let i=0; i<blocks.length; i++){
        let block = blocks[i]
        let analyzeData = block.aD
        if(analyzeData===null){
          //LOOP analyzeTypeCosts BY KEYS
          for(let key in analyzeTypeCosts){
            let analyzeTypeCost = analyzeTypeCosts[key]
            sum += analyzeTypeCost
          }
        }else{
          //LOOP BY analyzeType
          for(let j=0; j<this.analyzeType.length; j++){
            let analyzeType = this.analyzeType[j]
            //LOOP BY analyzeData KEYS
            for(let key in analyzeData){
              if(key==analyzeType){
                continue
              }else{
                let analyzeTypeCost = analyzeTypeCosts[analyzeType]
                sum += analyzeTypeCost
              }
            }
          }
        }
      }
      //INFLATION
      sum = sum * this.simulationSetup.actualInflation
      //RETURN
      return sum
    },
    content() {
      return this.contents.analyzeModal
    },
  },
  methods: {
    openModal(e, blocks) {
      e.simulationData.modals.analyze = blocks

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("analyzeModal"));
        modalInstance.show();
      }, 50);

    },
    analyze(){
      //VALIDATE
      let validate = new Validate()
      this.analyzeTypeError = validate.emptyField(this.analyzeType, this.content.errors.analyzeTypeRequired)

      if(this.analyzeTypeError){
        return false
      }
      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("analyzeModal"));
      modalInstance.hide();
      //ACTION
      this.Simulation.methods.action(this, 11, this.blocks, this.analyzeType)
    }
  },
};
</script>

<style scoped>
</style>
