export class NumberFunctions {
    constructor() {
    }

    formatNumber(number) {
        if (number !== null) {
            if (Number.isInteger(number)) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ꝟ";
            } else {
                return number.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ꝟ";
            }
        } else {
            return ""; // or any default value you prefer when the number is null
        }
    }
}
