import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";

import "bootstrap";
import "./assets/style.scss";
import "./assets/custom.css";
import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/duotone.css";
import "tom-select/dist/css/tom-select.bootstrap5.css";

const app = createApp(App);

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
axios.defaults.withCredentials = true;
app.config.globalProperties.$axios = axios;
axios.defaults.headers.common = {
    "Authorization": `Bearer ${localStorage.getItem("vMine_sessionHash")}`,
};

app.use(router, axios);
app.mount("#app");