<template>
  <div class="float-end">
    <div class="d-flex justify-content-between align-items-start">
      <div>
        <button class="btn btn-sm me-1 float-start" title="Setups" @click="Setup.methods.openOffset()">
          <i class="fa-duotone fa-gears"></i>
        </button>
        <button class="btn btn-sm btn-primary me-2 rounded float-start" @click="blockExcelExport">
          <i class="fa-duotone fa-file-export"></i><span> Export</span>
        </button>
        <button class="btn btn-sm btn-secondary me-2 rounded float-start" @click="Prices.methods.openOffset()">
          <i class="fa-duotone fa-money-bill-wave"></i> <span> {{nF.formatNumber(actualPrice)}}</span>
        </button>
        <button class="btn btn-sm btn-primary me-2 rounded float-start" @click="Timeline.methods.openOffset()">
          <i class="fa-duotone fa-timeline"></i><span> Timeline</span>
        </button>
        <div class="float-start position-relative">
          <button class="btn btn-sm btn-primary me-2 rounded" :class="{'disabled':goalSuccessNumber<goalNumber || actualDay!=realActualDay}" @click="phaseEndBtn">
            <i class="fa-duotone fa-octagon-exclamation"></i><span> End phase</span>
          </button>
          <div class="popover bs-popover-auto show" v-if="phaseEndPopOverShow" style="right: -17px;z-index: 1071" data-popper-placement="bottom">
            <div class="popover-arrow"></div>
            <div class="popover-body">
              <p>You can close this phase if you finished with all action.</p>
              <button class="btn btn-sm btn-secondary float-end" @click="nextDay(3)">Do it!</button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="float-start position-relative">
          <button class="btn btn-sm btn-primary me-2 rounded" @click="nextActionBtn" :class="{'disabled':stage===2 || stage===4 || actualDay!=realActualDay}" :disabled="stage===4">
            <i class="fa-duotone fa-forward-step"></i>
          </button>
          <div class="popover bs-popover-auto show" v-if="nextActionPopOverShow" style="right: -17px;z-index: 1071" data-popper-placement="bottom">
            <div class="popover-arrow"></div>
            <div class="popover-body">
              <p>You can jump to next action finish day.</p>
              <button class="btn btn-sm btn-secondary float-end" @click="nextDay(2)">Do it!</button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="float-start position-relative">
          <button class="btn btn-sm btn-primary rounded mb-2" @click="nextDayBtn" :class="{'disabled':stage===2 || stage===4 || actualDay!=realActualDay}" :disabled="stage===4">
            <i class="fa-duotone fa-forward"></i><span> Next day</span>
          </button>
          <div class="popover bs-popover-auto show" v-if="nextDayPopOverShow" style="right: 0;" data-popper-placement="bottom">
            <div class="popover-arrow"></div>
            <div class="popover-body">
              <p>If you finished with all action to these day, switch day.</p>
              <button class="btn btn-sm btn-secondary float-end" @click="nextDay(1)">Do it!</button>
              <div class="clearfix"></div>
            </div>
          </div>

        </div>
        <div class="clearfix"></div>
        <div class="float-end" v-if="teamFlowSheet!==null" style="cursor:pointer;" @click="flowSheet.methods.openModal">
          <i class="fa-duotone fa-rotate"></i> {{teamFlowSheetName}}
        </div>
      </div>
      <div class="text-end ms-2">
        <p class="stage">{{stageData.name}}
          <router-link :to="{name:'simulationOverview'}" v-if="stage!==4">
            <span class="badge text-bg-secondary" :class="{'text-bg-danger':goalSuccessNumber<goalNumber,'text-bg-success':goalSuccessNumber>=goalNumber}">
              {{goalSuccessNumber}}/{{goalNumber}}
            </span>
          </router-link>
        </p>
        <p class="day-year">DAY {{ actualDay }} YEAR {{ actualYear }}</p>
        <router-link :to="{name:'simulationProfitAndLoss'}" class="balance">{{ balance }}</router-link>
        <span class="ps-1" title="Finance excel export" @click="financeExcelExport"><i class="fa-duotone fa-file-export"></i></span>
      </div>
    </div>
  </div>
</template>



<script>
import Timeline from "@/views/Simulation/OffCanvas/Timeline";
import Prices from "@/views/Simulation/OffCanvas/Prices"
import FlowSheet from "@/views/Simulation/Modal/FlowSheet.vue";
import Setup from "@/views/Simulation/OffCanvas/Setup.vue";
import {Response} from "@/classes/response";
import Simulation from "@/views/Simulation/Simulation.vue";
import { NumberFunctions } from "@/classes/number";
import flowSheet from "@/views/Simulation/Modal/FlowSheet.vue";

export default {
  name: "SubHeader",
  components: { Prices, Timeline, Setup, Simulation },
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()
    let selectedCourseId = this.initData.user.selectedCourseId
    let nextDaySwitch = localStorage.getItem('vMine_'+selectedCourseId+'_nextDaySwitch') === "true" ? true : false
    let nextActionFinishSwitch = localStorage.getItem('vMine_'+selectedCourseId+'_nextActionFinishSwitch') === "true" ? true : false

    this.simulationData.setup.nextDaySwitch = nextDaySwitch
    this.simulationData.setup.nextActionFinishSwitch = nextActionFinishSwitch

    return {
      Prices,
      Timeline,
      FlowSheet,
      Setup,
      Simulation,
      nF,
      nextActionPopOverShow : false,
      nextDayPopOverShow : false,
      phaseEndPopOverShow : false,
    };
  },
  computed: {
    flowSheet() {
      return flowSheet
    },
    stage() {
      return this.simulationData.setup.actualStage
    },
    stageData(){
      let setup = this.simulationData.setup
      let stageData = setup.stages.find(stage => stage.id === this.stage)
      return stageData
    },
    actualDay() {
      return this.simulationData.setup.actualDay;
    },
    realActualDay() {
      return this.simulationData.setup.realActualDay;
    },
    actualYear() {
      return this.simulationData.setup.actualYear;
    },
    actualPrice() {
      return this.simulationData.prices.actual;
    },
    balance() {
      return this.nF.formatNumber(this.simulationData.finance.profitAndLoss.profitAfterTax);
    },
    goalNumber(){
      return Object.keys(this.simulationData.goals[this.stage]).length
    },
    goalSuccessNumber(){
      let goals = this.simulationData.goals[this.stage]
      //GET SUCCESS GOALS
      let successGoals = Object.keys(goals).filter((goal) => goals[goal].status === true)
      return successGoals.length
    },
    nextDaySwitch(){
      return this.simulationData.setup.nextDaySwitch
    },
    nextActionFinishSwitch(){
      return this.simulationData.setup.nextActionFinishSwitch
    },
    teamFlowSheet(){
      return this.simulationData.setup.teamFlowSheet
    },
    teamFlowSheetName(){
      if(this.teamFlowSheet===null){
        return null
      }
      let teamFlowSheet = this.simulationData.setup.flowSheets.find(f => f.id === this.teamFlowSheet)
      return teamFlowSheet.name
    },
    teamId(){
      return this.simulationData.setup.teamId
    }
  },
  watch:{
    actualDay(newVal,oldVal){
    }
  },
  methods: {
    nextDayBtn(){
      if(!this.nextDaySwitch){
        if(this.nextDayPopOverShow === true){
          this.nextDayPopOverShow = false
        }else{
          this.nextDayPopOverShow = true
          if(this.nextActionPopOverShow === true){
            this.nextActionPopOverShow = false
          }
          if(this.phaseEndPopOverShow === true){
            this.phaseEndPopOverShow = false
          }
        }
      }else{
        this.nextDay(1)
      }

    },
    nextActionBtn(){
      if(!this.nextActionFinishSwitch){
        if(this.nextActionPopOverShow === true){
          this.nextActionPopOverShow = false
        }else{
          this.nextActionPopOverShow = true
          if(this.nextDayPopOverShow === true){
            this.nextDayPopOverShow = false
          }
          if(this.phaseEndPopOverShow === true){
            this.phaseEndPopOverShow = false
          }
        }
      }else{
        this.nextDay(2)
      }
    },
    phaseEndBtn(){
      if(this.phaseEndPopOverShow === true){
        this.phaseEndPopOverShow = false
      }else{
        this.phaseEndPopOverShow = true
        if(this.nextDayPopOverShow === true){
          this.nextDayPopOverShow = false
        }
        if(this.nextActionPopOverShow === true){
          this.nextActionPopOverShow = false
        }
      }
    },
    nextDay(type){
      this.nextDayPopOverShow = false
      this.nextActionPopOverShow = false
      this.phaseEndPopOverShow = false
      Simulation.methods.nextDay(this,type)
    },
    blockExcelExport(){
      let day = null;

      this.initData.loader.visible = true;
      this.$axios.get('/api/v1/simulation/blockExcelExport/'+this.initData.user.selectedCourseId+'/'+this.teamId+'/'+day)
          .then((response) => {
            let responseData = new Response().handle(response);
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

            if (responseData.status === true) {
              let file = responseData.data.file
              let fileName = responseData.data.fileName
              let fileType = responseData.data.fileType
              const url = 'data:'+fileType+';base64,'+file;

              const downloadLink = document.createElement('a');
              document.body.appendChild(downloadLink);
              downloadLink.href = url;
              downloadLink.download = fileName;
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }

          })
          .catch((error) => {
            this.initData.alerts.push(new Response().handle(error.response));
          })
          .finally(() => {
            this.initData.loader.visible = false;
          });
    },
    financeExcelExport(){
      let teamId = null;
      let day = null;

      this.initData.loader.visible = true;
      this.$axios.get('/api/v1/simulation/financeExcelExport/'+this.initData.user.selectedCourseId+'/'+teamId+'/'+day)
          .then((response) => {
            let responseData = new Response().handle(response);
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

            if (responseData.status === true) {
              let file = responseData.data.file
              let fileName = responseData.data.fileName
              let fileType = responseData.data.fileType
              const url = 'data:'+fileType+';base64,'+file;

              const downloadLink = document.createElement('a');
              document.body.appendChild(downloadLink);
              downloadLink.href = url;
              downloadLink.download = fileName;
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }

          })
          .catch((error) => {
            this.initData.alerts.push(new Response().handle(error.response));
          })
          .finally(() => {
            this.initData.loader.visible = false;
          });
    }
  }
}
</script>

<style scoped>
.day-year{
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 5px;
}
.stage{
  color: var(--vmine-yellow);
  font-weight: 700;
  margin-bottom: 3px;
  font-size: 1rem;
  line-height: 1rem;
}
.balance{
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1rem;
  opacity: .7;
}
.popover{
  width: 300px;
  position: absolute;
  top: 40px;
}
.popover-arrow{
  position: absolute;
  right: 30px;
}
</style>