<template>
  <div v-if="simulationData.modals.tunnel.blocks.length>0" class="modal" id="tunnelModal" tabindex="-1" aria-labelledby="tunnelModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="tunnelModalLabel">
            <i class="fa-duotone fa-car-tunnel"></i> Make a tunnel
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <p>
              You will make a tunnel of the following blocks:<br>
              <span v-for="block in modalBlocks">
              #{{ block.id}}
              </span>
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary float-end" @click="tunnel">
              <i class="fa-duotone fa-car-tunnel"></i> Make a tunnel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap'
import Simulation from "@/views/Simulation/Simulation.vue";

export default {
  name: "Tunnel",
  components: { Simulation },
  props: ["initData", "contents", "simulationData"],
  data() {
    return {
      Simulation
    }
  },
  computed: {
    modalBlocks() {
      return this.simulationData.modals.tunnel.blocks
    },
    content() {
      return this.contents.tunnelModal
    },
  },
  methods: {
    tunnel(){
      this.Simulation.methods.action(this, 28, this.modalBlocks,null)

      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("tunnelModal"));
      modalInstance.hide();
    },
    getNeighborsPID(block, map) {
      const neighbors = [];
      const { x, y, l } = block;

      // Check for neighbors to the left, right, top, and bottom
      if (x > 0) neighbors.push(this.getBlockAt(x - 1, y, map, l))
      if (x < 40) neighbors.push(this.getBlockAt(x + 1, y, map, l))
      if (y > 0) neighbors.push(this.getBlockAt(x, y - 1, map, l))
      if (y < 40) neighbors.push(this.getBlockAt(x, y + 1, map, l))

      neighbors.forEach((neighbor) => {
        if (neighbor.pId !== undefined) {
          block.pId = neighbor.pId
        }
      });

      return neighbors.filter((neighbor) => neighbor !== undefined);
    },

    getBlockAt(x, y, map, l) {
      // Assuming you have access to the blocks array
      return map.find((block) => block.x === x && block.y === y && block.l === l);
    },
    openModal(e, blocks, map) {
      e.simulationData.modals.tunnel.blocks = blocks

      const allNeighbors = [];
      blocks.forEach((block) => {
        const neighbors = this.getNeighborsPID(block, map);
        neighbors.forEach((neighbor) => {
          allNeighbors.push(neighbor);
        });
      });

      console.log(allNeighbors.map((neighbor) => neighbor.pId));

      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("tunnelModal"));
        modalInstance.show();
      }, 0);
    }
  },
};
</script>

<style scoped>

</style>