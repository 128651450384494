<template>
  <div class="modal" id="flowSheetModal" tabindex="-1" aria-labelledby="flowSheetModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="flowSheetModalLabel">
            <i class="fa-duotone fa-rotate"></i> Flowsheet change
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>
            You will change current flowsheet:<br>
            {{teamFlowSheetName}}
          </p>
          <div class="mb-3">
            <label for="flowSheet">New flowsheet*</label>
            <select class="form-control" id="flowSheet" v-model="flowSheet">
              <option value="">Select new flowsheet</option>
              <option v-for="flowSheet in flowSheets" :value="flowSheet.id">{{flowSheet.name}}</option>
            </select>
            <div class="small text-danger" v-if="flowSheetError">
              {{flowSheetError}}
            </div>
          </div>
          <p>
            New flowsheet price: {{nF.formatNumber(flowSheetPrice)}}<br>
            Old flowsheet sales price: {{nF.formatNumber(flowSheetSalesPrice)}}<br>
            Installment costs: {{nF.formatNumber(flowSheetInstallmentCost)}}
          </p>
          <p>Are you sure change?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary float-end" @click="change">
            <i class="fa-duotone fa-rotate"></i> Change
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NumberFunctions} from "@/classes/number";
import { Modal } from "bootstrap";
import Simulation from "@/views/Simulation/Simulation.vue";
import {Validate} from "@/classes/validate";
import flowSheet from "@/views/Simulation/Modal/FlowSheet.vue";

export default {
  name: "FlowSheet",
  components: {Simulation},
  props: ["initData", "contents", "simulationData"],
  data() {
    let nF = new NumberFunctions()

    return {
      nF,
      flowSheet: '',
      flowSheetError: null,
      Simulation
    };
  },
  computed: {
    teamFlowSheet(){
      return this.simulationData.setup.teamFlowSheet
    },
    teamFlowSheetName(){
      if(this.teamFlowSheet===null){
        return null
      }
      let teamFlowSheet = this.simulationData.setup.flowSheets.find(f => f.id === this.teamFlowSheet)
      return teamFlowSheet.name
    },
    flowSheets(){
      return this.simulationData.setup.flowSheets.filter(f => f.id !== this.teamFlowSheet)
    },
    flowSheetData(){
      return this.flowSheets.find((flowSheet) => flowSheet.id === parseInt(this.flowSheet))
    },
    flowSheetPrice(){
      if(!this.flowSheetData){
        return 0
      }
      let price = 0
      if(this.flowSheetData.ballMill>this.oldFlowSheetData.ballMill){
        price += this.flowSheetData.ballMill
      }
      if(this.flowSheetData.sagMill>this.oldFlowSheetData.sagMill){
        price += this.flowSheetData.sagMill
      }
      if(this.flowSheetData.gravitySpirals>this.oldFlowSheetData.gravitySpirals){
        price += this.flowSheetData.gravitySpirals
      }
      if(this.flowSheetData.flotation>this.oldFlowSheetData.flotation){
        price += this.flowSheetData.flotation
      }
      if(this.flowSheetData.magneticSeparation>this.oldFlowSheetData.magneticSeparation){
        price += this.flowSheetData.magneticSeparation
      }
      return price
    },
    oldFlowSheetData(){
      return this.simulationData.setup.flowSheets.find((flowSheet) => flowSheet.id === this.teamFlowSheet)
    },
    flowSheetSalesPrice(){
      if(!this.flowSheetData){
        return 0
      }
      let price = 0
      if(this.flowSheetData.ballMill<this.oldFlowSheetData.ballMill){
        price += this.oldFlowSheetData.ballMill
      }
      if(this.flowSheetData.sagMill<this.oldFlowSheetData.sagMill){
        price += this.oldFlowSheetData.sagMill
      }
      if(this.flowSheetData.gravitySpirals<this.oldFlowSheetData.gravitySpirals){
        price += this.oldFlowSheetData.gravitySpirals
      }
      if(this.flowSheetData.flotation<this.oldFlowSheetData.flotation){
        price += this.oldFlowSheetData.flotation
      }
      if(this.flowSheetData.magneticSeparation<this.oldFlowSheetData.magneticSeparation){
        price += this.oldFlowSheetData.magneticSeparation
      }
      return price * (1 - this.simulationData.setup.other.sellingUsedFlowSheetPenalty)

    },
    flowSheetInstallmentCost(){
      if(!this.flowSheetData){
        return 0
      }
      let piece = 0
      if(this.flowSheetData.ballMill!==this.oldFlowSheetData.ballMill){
        piece += 1
      }
      if(this.flowSheetData.sagMill!==this.oldFlowSheetData.sagMill){
        piece += 1
      }
      if(this.flowSheetData.gravitySpirals!==this.oldFlowSheetData.gravitySpirals){
        piece += 1
      }
      if(this.flowSheetData.flotation!==this.oldFlowSheetData.flotation){
        piece += 1
      }
      if(this.flowSheetData.magneticSeparation!==this.oldFlowSheetData.magneticSeparation){
        piece += 1
      }
      return piece * this.simulationData.setup.costs.flowSheetInstallCost
    },
    content() {
      return this.contents.flowSheetModal
    },
  },
  methods: {
    openModal() {
      setTimeout(() => {
        let modalInstance = new Modal(document.getElementById("flowSheetModal"));
        modalInstance.show();
      }, 50);

    },
    change(){
      //VALIDATE
      let validate = new Validate()
      this.flowSheetError = validate.selected(this.flowSheet, this.content.errors.flowSheetRequired)

      if(this.flowSheetError){
        return false
      }
      //CLOSE MODAL
      let modalInstance = Modal.getInstance(document.getElementById("flowSheetModal"));
      modalInstance.hide();
      //ACTION
      this.Simulation.methods.action(this, 16, null, {'flowSheet' : this.flowSheet})
      //RESET
      this.flowSheet = ''
    }
  },
};
</script>

<style scoped>
</style>
