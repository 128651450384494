<template>
    <router-view v-if="routerInited" :initData="initData" :contents="contents" :simulationData="simulationData" />
    <TestDrill v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></TestDrill>
    <Analyze v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Analyze>
    <Test v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Test>
    <Timeline v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Timeline>
    <Prices v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Prices>
    <FlowSheet v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></FlowSheet>
    <Setup v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Setup>
    <Road v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Road>
    <NewProject v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></NewProject>
    <CloseProject v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></CloseProject>
    <OpenShaft v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></OpenShaft>
    <Mine v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Mine>
    <Sell v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Sell>
    <Tunnel v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Tunnel>
    <Processing v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Processing>
    <Backfilling v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Backfilling>
    <TailingsModal v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></TailingsModal>
    <Footer v-if="simulationInited" :initData="initData" :contents="contents" :simulationData="simulationData"></Footer>
</template>

<script>
import {Response} from "@/classes/response"
import {reactive} from "vue"
import Map from "@/views/Simulation/Map/Map"
import TestDrill from "@/views/Simulation/Modal/TestDrill"
import Analyze from "@/views/Simulation/Modal/Analyze"
import Test from "@/views/Simulation/Modal/Test"
import Timeline from "@/views/Simulation/OffCanvas/Timeline"
import Prices from "@/views/Simulation/OffCanvas/Prices"
import FlowSheet from "@/views/Simulation/Modal/FlowSheet.vue";
import Setup from "@/views/Simulation/OffCanvas/Setup.vue"
import Road from "@/views/Simulation/Modal/Road.vue"
import NewProject from "@/views/Simulation/Modal/NewProject.vue";
import CloseProject from "@/views/Simulation/Modal/CloseProject.vue";
import OpenShaft from "@/views/Simulation/Modal/OpenShaft.vue";
import Mine from "@/views/Simulation/Modal/Mine.vue"
import Sell from "@/views/Simulation/Modal/Sell.vue";
import Tunnel from "@/views/Simulation/Modal/Tunnel.vue";
import Processing from "@/views/Simulation/Modal/Processing.vue";
import Backfilling from "@/views/Simulation/Modal/Backfilling.vue";
import TailingsModal from "@/views/Simulation/Modal/Tailings.vue";
import Footer from "@/views/Simulation/components/Footer.vue";

export default {
    name: "Simulation",
    components: {
      TailingsModal, Backfilling, Processing, Tunnel,
      Sell, OpenShaft, FlowSheet, TestDrill, Analyze, Test, Timeline, Prices, Setup, Road, NewProject, CloseProject, Mine, Footer },
    props: ["initData", "contents"],
    data() {
        const blocksForActions = localStorage.getItem('vMine_'+this.initData.user.selectedCourseId+'_blocksForActions') !== null ? JSON.parse(localStorage.getItem('vMine_'+this.initData.user.selectedCourseId+'_blocksForActions')) : [];

        const simulationData = reactive({
            "status": null,
            "setup": null,
            "blocks": null,
            "histories": null,
            "finance": null,
            "statistics" : null,
            "prices": null,
            "projects": null,
            "modals" : {
                "testDrill" : null,
                "analyze" : null,
                "test": null,
                "timeline": null,
                "prices": null,
                "road": null,
                "openShaft": {
                    "blocks": []
                },
                "mine": {
                    "blocks": []
                },
                "newProject": {
                  "blocks": [],
                  "plantPathLength": null,
                  "tailingPathLength": null,
                  "rawStockPathLength": null,
                  "switcher": false
                },
                "closeProject": {
                  "projectId": null
                },
                "sell": {
                  "blocks": []
                },
                "tunnel": {
                  "blocks": []
                },
                "processing": {
                  "blocks": []
                },
                "backfilling": {
                  "blocks": []
                },
                "tailings": {
                  "blocks": []
                }
            },
            "isShaftRequired": false,
            "shaftRequiredProjectId": null,
            "blocksForActions" : blocksForActions,
            "hoveredBlock": null,
            "constructions": {
              "isSaved": false,
              "plant": {
                "activated": false,
                "isValid": false,
                "rotated": false,
                "placed": false,
                "blocks": [],
              },
              "plantEntry" : {
                "activated": false,
                "isValid": false,
                "placed": false,
                "blocks": []
              },
              "tailingEntry": {
                "activated": false,
                "isValid": false,
                "placed": false,
                "blocks": [],
              },
              "rawStockEntry": {
                "activated": false,
                "isValid": false,
                "placed": false,
                "blocks": [],
              }
            },
            "goals" : {
              1 : {
                1 : {
                  "status" : false,
                  "value" : 0
                },
                2 : {
                  "status" : false,
                  "value" : 0
                },
                3 : {
                  "status" : false,
                  "value" : 0
                },
                4 : {
                  "status" : false,
                  "value" : 0
                }
              },
              2 : {
                1: {
                  "status": false,
                  "value": 0
                },
                2: {
                  "status": false,
                  "value": 0
                },
                3: {
                  "status": false,
                  "value": 0
                },
                4: {
                  "status": false,
                  "value": 0
                },
                5: {
                  "status": false,
                  "value": 0
                }
              },
              3 : {
                1: {
                  "status": false
                },
                2: {
                  "status": false
                },
                3: {
                  "status": false
                },
                4: {
                  "status": false
                },
              },
              4 : {
                1: {
                  "status": false
                }
              }
            }
        });

        return {
            simulationData,
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        simulationInited(){
          let simulationInited = false;
          if(this.simulationData.setup !== null) {
            simulationInited = true;
          }
          return simulationInited;
        },
        routerInited(){
          let routerInited = false;
          if(this.simulationData.setup === null && this.currentRouteName==="simulationOverview"){
            routerInited = true;
          }else if(this.simulationData.setup !== null) {
            routerInited = true;
          }
          return routerInited;
        },
        stage() {
          return this.simulationData.setup.actualStage
        },
        actualLevel() {
          return this.simulationData.setup.actualLevel;
        },
        userIsGameRulesRead(){
          return this.initData.user.isGameRulesRead
        },
        gameRulesReaded(){
          //console.log('gameRulesReaded')
          if(this.simulationData.setup !== null){
            //console.log(this.simulationData.setup.isGameRulesRead)
            let isGameRulesRead = this.simulationData.setup.isGameRulesRead
            if(isGameRulesRead===1){
              //console.log('hahó')
              this.simulationData.goals[1][1]["status"] = true
            }
          }
        },
        testDrillBlocks(){
          let blocks = this.simulationData.blocks
          if(blocks===null){
            return []
          }
          let testDrillBlocks = blocks.filter((block) => block.s === 3)
          let testDrillBlocksLength = testDrillBlocks.length
          this.simulationData.goals[1][2]["status"] = testDrillBlocksLength >= 10
          this.simulationData.goals[1][2]["value"] = testDrillBlocksLength
          return testDrillBlocks
        },
        analyzeBlocks(){
          let blocks = this.simulationData.blocks
          if(blocks===null){
            return []
          }
          let analyzeBlocks = blocks.filter((block) => block.s === 3 && block.aD !== null)
          //COUNT FULLY ANALYZED BLOCKS
          let analyzedBlocks = 0
          //LOOP analyzeBlocks
          for (let i = 0; i < analyzeBlocks.length; i++) {
            let analyzeBlock = analyzeBlocks[i]
            let analyzeBlockAd = analyzeBlock.aD
            let analyzeBlockAdKeys = Object.keys(analyzeBlockAd)
            if(analyzeBlockAdKeys.length === 2){
              analyzedBlocks++
            }
          }
          this.simulationData.goals[1][3]["status"] = analyzedBlocks >= 2
          this.simulationData.goals[1][3]["value"] = analyzedBlocks
          return analyzeBlocks
        },
        activeAction(){
          let blocks = this.simulationData.blocks
          if(blocks===null){
            return false
          }
          let activeAction = blocks.filter((block) => block.s === 2 || block.s === 4 || block.s === 5)
          this.simulationData.goals[1][4]["status"] = activeAction.length === 0
          return activeAction
        },
        teamFlowSheetSelected(){
          let teamFlowSheetSelected = false
          if(this.simulationData.setup !== null
              && this.simulationData.setup.hasOwnProperty('teamFlowSheet')
              && this.simulationData.setup.teamFlowSheet !== ''
              && this.simulationData.setup.teamFlowSheet !== null){
            teamFlowSheetSelected = true
          }
          this.simulationData.goals[2][1]["status"] = teamFlowSheetSelected
        },
        mapSelectedLevelBlocks() {
          const selectedLayer = [];
          if (this.simulationData.setup=== null) {
            return selectedLayer;
          }
          const x = this.simulationData.setup.map.x;
          const y = this.simulationData.setup.map.y;
          const blocks = this.simulationData.blocks;
          const actualLevel = this.actualLevel;
          // Get layer
          const actualBlocks = blocks.slice(actualLevel * x * y - x * y, actualLevel * x * y);
          // Calculate the selectedLayer array with reordered blocks
          for (let i = y - 1; i >= 0; i--) {
            for (let j = 0; j < x; j++) {
              const index = j * y + i;
              selectedLayer.push(actualBlocks[index]);
            }
          }
          return selectedLayer;
        },
        plantIsPlaced(){
          this.simulationData.goals[2][2]["status"] = this.simulationData.constructions.plant.placed
        },
        plantEntryIsPlaced(){
          this.simulationData.goals[2][3]["status"] = this.simulationData.constructions.plantEntry.placed
        },
        tailingEntryIsPlaced(){
          this.simulationData.goals[2][4]["status"] = this.simulationData.constructions.tailingEntry.placed
        },
        rawStockEntryIsPlaced(){
          this.simulationData.goals[2][5]["status"] = this.simulationData.constructions.rawStockEntry.placed
        },
        plantIsConnected(){
          if (this.simulationData.setup===null || this.stage!==3){
            return false
          }
          let roadBlocksNumber = this.mapSelectedLevelBlocks.filter((block) => block.s === 10).length
          if(roadBlocksNumber===0){
            return false
          }
          let status = this.roadConnectionCheck(this,this.mapSelectedLevelBlocks)
          this.simulationData.goals[3][1]["status"] = status.globalStatus
        },
        projectIsStarted(){
          let projects = this.simulationData.projects
          if(projects===null || this.stage!==3){
            return false
          }
          if(projects.length>0){
            this.simulationData.goals[3][2]["status"] = true
          }
        },
        allProjectClosed(){
          let projects = this.simulationData.projects
          if(projects===null || this.stage!==3){
            return false
          }
          let allProjectClosed = true
          projects.forEach(function (project) {
            if(project.isOpened===true){
              allProjectClosed = false
            }
          })
          this.simulationData.goals[3][3]["status"] = allProjectClosed
        },
        stocksIsEmpty(){
          let blocks = this.simulationData.blocks
          if(blocks===null || this.stage!==3){
            return false
          }
          let stockIsEmpty = true
          let stockStatuses = [21,24]
          let stocks = blocks.filter((block) => stockStatuses.includes(block.s))
          if(stocks.length > 0){
            stockIsEmpty = false
          }
          this.simulationData.goals[3][4]["status"] = stockIsEmpty
        },
        isFinanceChecked(){
          this.simulationData.goals[4][1]["status"] = this.simulationData.isFinanceChecked
        }
    },
    watch:{
      currentRouteName() {
        if(this.currentRouteName !== "simulationOverview" && this.simulationData.setup === null){
          this.$router.push({name: "simulationOverview"})
        }
      },
      userIsGameRulesRead(){
        this.simulationData.setup.isGameRulesRead = this.userIsGameRulesRead
      },
      gameRulesReaded(){},
      testDrillBlocks(){},
      analyzeBlocks(){},
      activeAction(){},
      teamFlowSheetSelected(){},
      plantIsPlaced(){},
      plantEntryIsPlaced(){},
      tailingEntryIsPlaced(){},
      rawStockEntryIsPlaced(){},
      plantIsConnected(){},
      projectIsStarted(){},
      allProjectClosed(){},
      stocksIsEmpty(){},
      isFinanceChecked(){}
    },
    created() {
        this.getSimulationData(this,null,null);
    },
    methods: {
        getSimulationData(e,teamId,day) {
          e.initData.loader.visible = true;
          e.$axios.get('/api/v1/simulation/'+e.initData.user.selectedCourseId+'/'+teamId+'/'+day)
              .then((response) => {
                  let responseData = new Response().handle(response);
                  e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

                  if (responseData.status === true) {
                    let status = responseData.data.status
                    e.simulationData.status = status
                    if(status === 2){
                      e.$router.push({name: "courses"})
                    }else if(status === 1) {
                      //ACTUAL LEVEL
                      let actualLevel = 1
                      if(e.simulationData.setup!==null){
                        actualLevel = e.simulationData.setup.actualLevel
                      }
                      //IF STORED LEVEL NOT SAME AS ACTUAL LEVEL
                      let storageLevel = localStorage.getItem('vMine_' + e.initData.user.selectedCourseId + '_actualLevel')
                      if (storageLevel !== null && storageLevel !== undefined) {
                        actualLevel = parseInt(storageLevel)
                      }
                      //DATA
                      e.simulationData.setup = responseData.data.setup
                      e.simulationData.blocks = responseData.data.blocks
                      e.simulationData.histories = responseData.data.histories
                      e.simulationData.finance = responseData.data.finance
                      e.simulationData.statistics = responseData.data.statistics
                      e.simulationData.prices = responseData.data.prices
                      e.simulationData.projects = responseData.data.projects
                      e.simulationData.isShaftRequired = responseData.data.isShaftRequired
                      e.simulationData.shaftRequiredProjectId = responseData.data.shaftRequiredProjectId
                      e.simulationData.isFinanceChecked = responseData.data.isFinanceChecked
                      e.simulationData.documents = responseData.data.documents
                      //NOT CHANGE LEVEL
                      if(e.simulationData.setup.actualLevel!==actualLevel){
                        e.simulationData.setup.actualLevel = actualLevel
                      }
                    }else{
                      e.$router.push({name: "simulationOverview"})
                    }
                  }

              })
              .catch((error) => {
                  e.initData.alerts.push(new Response().handle(error.response));
              })
              .finally(() => {
                  e.initData.loader.visible = false;
              });
        },
        action(e,action,blocks,data){
          let teamId = e.simulationData.setup.teamId
          let postData = {
            "action": action,
            "blocks": blocks,
            "data": data
          }

          e.initData.loader.visible = true;
          e.$axios.post('/api/v1/simulation/action/'+e.initData.user.selectedCourseId+'/'+teamId, postData)
              .then((response) => {
                let responseData = new Response().handle(response);
                e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

                if (responseData.status === true) {
                  let modifiedBlocks = responseData.data.modifiedBlocks
                  //UPDATE BLOCKS
                  if(modifiedBlocks.length > 0){
                    modifiedBlocks.forEach(function (modifiedBlock) {
                      let blockIndex = e.simulationData.blocks.findIndex((block) => block.id === modifiedBlock.id);
                      e.simulationData.blocks[blockIndex] = modifiedBlock
                    })
                  }
                  //RESET BLOCKS FOR ACTIONS
                  e.simulationData.blocksForActions = [];
                  //REFRESH LOCAL STORAGE
                  localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));
                  //UPDATE FINANCE
                  e.simulationData.finance = responseData.data.finance
                  //UPDATE HISTORIES
                  e.simulationData.histories = responseData.data.histories
                  //UPDATE STATISTICS
                  e.simulationData.statistics = responseData.data.statistics
                  //UPDATE PROJECTS
                  e.simulationData.projects = responseData.data.projects
                  //IS SHAFT REQUIRED
                  e.simulationData.isShaftRequired = responseData.data.isShaftRequired
                  e.simulationData.shaftRequiredProjectId = responseData.data.shaftRequiredProjectId
                  //STAGE 2 PHASE END
                  if(e.stage === 2){
                    e.simulationData.constructions.isSaved = true
                    e.Simulation.methods.nextDay(e,2)
                  }
                  // //ACTION 15
                  // if (action === 15) {
                  //   if(data.isShaftRequired===true){
                  //     e.simulationData.isShaftRequired = true
                  //   }
                  // }
                  // //ACTION 17
                  // if (action === 17) {
                  //   e.simulationData.isShaftRequired = false
                  // }

                  //ACTION 26
                  if (action === 26) {
                    let upgradeId = data.upgradeId
                    let upgradeData = e.simulationData.setup.upgrades.find((upgrade) => upgrade.id === upgradeId)
                    if(upgradeData!==undefined){
                      if(data.upgradeId==1){
                        e.simulationData.setup.maxRawBlockStockpile = upgradeData.effect
                      }else if (data.upgradeId==2){
                        e.simulationData.setup.maxProcessBlockStockpile = upgradeData.effect
                      }
                    }

                  }
                }else{
                  if(action>18 && action<23){
                    e.simulationData.isShaftRequired = false
                  }
                }

              })
              .catch((error) => {
                e.initData.alerts.push(new Response().handle(error.response));
              })
              .finally(() => {
                    if(e.stage === 2 && e.simulationData.constructions.isSaved === true){

                    }else{
                      e.initData.loader.visible = false;
                    }

              });
        },
        nextDay(e,type){
          //DISABLE MULTIPLE CLICKS
          // if (e.initData.loader.visible === true) {
          //   return false
          // }
          //STAGE 2 IF BUILD ACTION NOT STARTED
          let startStage = e.stage
          if(type===3 && e.stage === 2 && e.simulationData.constructions.isSaved === false){
            //BUILD ACTION
            e.Simulation.methods.planning(e)
            return false
          }
          let teamId = null
          let postData = {
            type: type
          }
          e.initData.loader.visible = true;
          e.$axios.post('/api/v1/simulation/nextDay/'+e.initData.user.selectedCourseId+'/'+teamId, postData)
              .then((response) => {
                let responseData = new Response().handle(response);
                e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

                if (responseData.status === true) {
                  if (e.stage===3 && type===3){
                    e.$router.push({name: "simulationOverview"})
                  }
                  let modifiedBlocks = responseData.data.modifiedBlocks
                  //UPDATE BLOCKS
                  let that = e
                  modifiedBlocks.forEach(function (modifiedBlock) {
                    let blockIndex = that.simulationData.blocks.findIndex((block) => block.id === modifiedBlock.id);
                    that.simulationData.blocks[blockIndex] = modifiedBlock
                  })
                  //RESET BLOCKS FOR ACTIONS
                  e.simulationData.blocksForActions = [];
                  //REFRESH LOCAL STORAGE
                  localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));
                  //UPDATE FINANCE
                  e.simulationData.finance = responseData.data.finance
                  //UPDATE DAY, YEAR, INFLATION, STAGE
                  e.simulationData.setup.actualDay = responseData.data.actualDay
                  e.simulationData.setup.realActualDay = responseData.data.realActualDay
                  e.simulationData.setup.actualYear = responseData.data.actualYear
                  e.simulationData.setup.actualInflation = responseData.data.actualInflation
                  e.simulationData.setup.actualStage = responseData.data.actualStage
                  //UPDATE HISTORY
                  e.simulationData.histories = responseData.data.histories
                  //UPDATE STATISTICS
                  e.simulationData.statistics = responseData.data.statistics
                  //UPDATE PROJECTS
                  e.simulationData.projects = responseData.data.projects
                  //UPDATE PRICES
                  e.simulationData.prices = responseData.data.prices
                  //UPDATE TEAM FLOWSHEET ID
                  e.simulationData.setup.teamFlowSheet = responseData.data.teamFlowSheet
                }

              })
              .catch((error) => {
                e.initData.alerts.push(new Response().handle(error.response));
              })
              .finally(() => {
                e.initData.loader.visible = false;
                //STAGE 2 END TO ACTION
                if(type===2 && startStage === 2){
                  e.Simulation.methods.nextDay(e,3)
                }
              });
        },
        blockForAction(e,blockId){
          const isBlockAlreadyForAction = e.simulationData.blocksForActions.some(
              (block) => block.id === blockId
          );
          if (!isBlockAlreadyForAction) {
            let blockData = e.simulationData.blocks.find((block) => block.id === blockId);
            //STAGE 1
            if (e.actualStage === 1) {
              //IF SELECT TO ANALYZE
              if(blockData.s!==3 && blockData.aD===null && e.simulationData.blocksForActions.some((block) => block.s === 3 && block.aD===null)){
                e.initData.alerts.push({msg: e.content.errors.blockSelectedToAnalyze, type: 'error'});
                return false
              }
              //ANALYZING
              if(blockData.s===4){
                e.initData.alerts.push({msg: e.content.errors.blockAnalyzing, type: 'error'});
                return false
              }
              //TESTING
              if(blockData.s===5){
                e.initData.alerts.push({msg: e.content.errors.blockTesting, type: 'error'});
                return false
              }
              //DRILLING
              if(blockData.s===2){
                e.initData.alerts.push({msg: e.content.errors.blockDrilling, type: 'error'});
                return false
              }
              //TEST DRILL IN STAGE ONE
              //OTHER STATUS BUT THERE IS TEST DRILL IN ACTION
              if (blockData.s !== 1 && e.simulationData.blocksForActions.some((block) => block.s === 1)) {
                e.initData.alerts.push({msg: e.content.errors.blockSelectedToTestDrill, type: 'error'});
                return false
              }
              //THERE IS OTHER STATUS IN ACTIONS
              if (blockData.s === 1 && e.simulationData.blocksForActions.some((block) => block.s !== 1)) {
                e.initData.alerts.push({msg: e.content.errors.blockSelectedToNotTestDrill, type: 'error'});
                return false
              }
              //THERE IS BLOCK IN BLOCKS FOR ACTIONS WITH STATUS ONE
              if (blockData.s === 1 && e.simulationData.blocksForActions.some((block) => block.s === 1)) {
                e.initData.alerts.push({msg: e.content.errors.blockSelectedToTestDrill, type: 'error'});
                return false
              }
              //LEVEL NOT 1
              if(blockData.s === 1 && e.actualLevel!==1) {
                e.initData.alerts.push({msg: e.content.errors.blockNotLevel1ToTestDrill, type: 'error'});
                return false
              }
              //GET BLOCK STATUS
              if(blockData.s === 1 && blockData.s!==1) {
                e.initData.alerts.push({msg: e.content.errors.blockNotGoodStatusToTestDrill, type: 'error'});
                return false
              }
              //IF TEST DRILL, SELECT ALL BLOCKS WHERE X Y EQUAL, BUT DIFFERENT LEVEL
              if(blockData.s === 1 && e.actualLevel===1) {
                let blocks = e.simulationData.blocks.filter((block) => block.x === blockData.x && block.y === blockData.y && block.l !== blockData.l);
                blocks.forEach((block) => {
                  e.simulationData.blocksForActions.push(block);
                });
              }

            }

            if (e.actualStage === 1 || e.actualStage === 3){
              e.simulationData.blocksForActions.push(blockData);
              //BLOCKS FOR ACTIONS ORDER BY BLOCK ID
              e.simulationData.blocksForActions.sort((a, b) => a.id - b.id);
              //BLOCKS FOR ACTIONS TO LOCAL STORAGE
              localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));
            }
            if (e.actualStage === 2){
              //PLANT BUILD
              if(e.plantActivated && e.simulationData.constructions.plant.isValid){
                e.plantBlocks.forEach((block) => {
                  block.oldS = block.s
                  block.s = 6
                });
                e.simulationData.constructions.plant.placed = true
                e.simulationData.constructions.plant.activated = false
              }
              // PLANT ENTRANCE BUILD
              if(e.plantEntryActivated && e.simulationData.constructions.plantEntry.isValid){
                e.simulationData.constructions.plantEntry.previousBlock = e.simulationData.hoveredBlock
                e.simulationData.constructions.plantEntry.blocks.push(e.simulationData.hoveredBlock)
                e.plantEntryBlocks.forEach((block) => {
                  block.s = 7
                });
                e.simulationData.constructions.plantEntry.placed = true
                e.simulationData.constructions.plantEntry.activated = false
              }
              // TAILING ENTRY BUILD
              if(e.tailingEntryActivated && e.simulationData.constructions.tailingEntry.isValid){
                e.simulationData.constructions.tailingEntry.previousBlock = e.simulationData.hoveredBlock
                e.simulationData.constructions.tailingEntry.blocks.push(e.simulationData.hoveredBlock)
                console.log(e.simulationData.hoveredBlock)
                e.tailingEntryBlocks.forEach((block) => {
                  block.oldS = block.s
                  block.s = 8
                });
                e.simulationData.constructions.tailingEntry.placed = true
                e.simulationData.constructions.tailingEntry.activated = false
              }
              // RAW STOCK ENTRY BUILD
              if(e.rawStockEntryActivated && e.simulationData.constructions.rawStockEntry.isValid){
                e.simulationData.constructions.rawStockEntry.blocks.push(e.simulationData.hoveredBlock)
                e.rawStockEntryBlocks.forEach((block) => {
                  block.oldS = block.s
                  block.s = 22
                });
                e.simulationData.constructions.rawStockEntry.placed = true
                e.simulationData.constructions.rawStockEntry.activated = false
              }
            }

          }else{
            // remove the block from the blocksForActions
            e.simulationData.blocksForActions = e.simulationData.blocksForActions.filter((block) => block.id !== blockId);
            //BLOCKS FOR ACTIONS TO LOCAL STORAGE
            localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));

          }
        },
        removeAllBlocksFromActions(e){
          e.simulationData.blocksForActions = [];
          //BLOCKS FOR ACTIONS TO LOCAL STORAGE
          localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));
        },
        planning(e){
          let plantBlocks = e.simulationData.constructions.plant.blocks
          let plantEntryBlocks = e.simulationData.constructions.plantEntry.blocks
          let tailingEntryBlocks = e.simulationData.constructions.tailingEntry.blocks
          let rawStockEntryBlocks = e.simulationData.constructions.rawStockEntry.blocks
          let blocks = plantBlocks.concat(plantEntryBlocks,tailingEntryBlocks,rawStockEntryBlocks)

          //GET UNIQUE BLOCKS BY ID
          blocks = blocks.filter((block, index, self) =>
                  index === self.findIndex((t) => (
                      t.id === block.id
                  ))
          )

          e.Simulation.methods.action(e,13,blocks,{
            flowSheet: e.simulationData.setup.teamFlowSheet
          })
        },
        createStatusMap(blocks){
          //CREATE 2D ARRAY FROM BLOCK WITH ONLY BLOCK STATUS VALUE NEW LINE PER 40
          let map = [];
          let mapRow = [];
          let mapRowLength = 0;
          blocks.forEach((block) => {
            mapRow.push(block.s);
            mapRowLength++;
            if(mapRowLength===40){
              map.push(mapRow);
              mapRow = [];
              mapRowLength = 0;
            }
          });
          return map;

        },
      translateCoordinates(startMapCoord, endMapCoord) {
        // Extract the x and y values from the start and end map coordinates
        const startMapX = startMapCoord.x;
        const startMapY = startMapCoord.y;
        const endMapX = endMapCoord.x;
        const endMapY = endMapCoord.y;

        // Reverse the orientation by inverting the Y-axis and adding 1
        let reversedStartY = 41 - startMapY;
        let reversedEndY = 41 - endMapY;

        // Translate the start and end map coordinates to the path finding algorithm coordinates
        let startPathX = startMapX - 1;
        let startPathY = reversedStartY - 1;
        let endPathX = endMapX - 1;
        let endPathY = reversedEndY - 1;

        // Perform boundary checks to ensure the translated coordinates stay within the map bounds
        startPathX = Math.max(0, Math.min(39, startPathX));
        startPathY = Math.max(0, Math.min(39, startPathY));
        endPathX = Math.max(0, Math.min(39, endPathX));
        endPathY = Math.max(0, Math.min(39, endPathY));

        // Return the translated start and end path finding algorithm coordinates as objects
        return {
          start: { y: startPathX, x: startPathY },
          end: { y: endPathX, x: endPathY }
        };
      },
      findPath(e,start, end, map, blockS) {
        // which the coordinates of the start and end blocks
        const coordinates = this.translateCoordinates(start, end);
        start = coordinates.start;
        end = coordinates.end;

        map = this.createStatusMap(map);
        const numRows = map.length;
        const numCols = map[0].length;
        const queue = [{ x: start.x, y: start.y, steps: 0 }];
        const visited = new Set();

        while (queue.length > 0) {
          const current = queue.shift();

          if (current.x === end.x && current.y === end.y) {
            return current.steps;
          }

          // Check adjacent blocks (up, down, left, right)
          const directions = [
            { dx: 1, dy: 0 },
            { dx: -1, dy: 0 },
            { dx: 0, dy: 1 },
            { dx: 0, dy: -1 },
          ];

          for (const dir of directions) {
            const nextX = current.x + dir.dx;
            const nextY = current.y + dir.dy;

            if (
              nextX >= 0 && nextX < numRows &&
              nextY >= 0 && nextY < numCols &&
              (
                map[nextX][nextY] === blockS ||
                map[nextX][nextY] === 7 ||  // plant entrance
                map[nextX][nextY] === 12 || // underground entrance
                map[nextX][nextY] === 11 || // open-pit entrance
                map[nextX][nextY] === 8 ||  // tailing entrance
                map[nextX][nextY] === 22    // raw stock entrance
              ) && !visited.has(`${nextX},${nextY}`)
            ) {
              queue.push({
                x: nextX,
                y: nextY,
                steps: current.steps + 1,
              });
              visited.add(`${nextX},${nextY}`);
            }
          }
        }
        return false; // No path found

      },

      roadConnectionCheck(e, map) {
        const entrances = {
          plant: 7,
          tailings: 8,
          rawStock: 22,
          projectEntrances: [11, 12],
        };

        const result = [];
        let globalStatus = false;

        const plantEntrance = map.find((block) => block.s === entrances.plant);
        const tailingEntrance = map.find((block) => block.s === entrances.tailings);
        const rawStockEntrance = map.find((block) => block.s === entrances.rawStock);

        const projectEntrances = map.filter((block) => entrances.projectEntrances.includes(block.s));

        for (let i = 0; i < projectEntrances.length; i++) {
          const projectEntrance = projectEntrances[i];

          const plantPathLength = this.findPath(e, projectEntrance, plantEntrance, map, 10);
          const tailingPathLength = this.findPath(e, projectEntrance, tailingEntrance, map, 10);
          const rawStockPathLength = this.findPath(e, projectEntrance, rawStockEntrance, map, 10);

          const statuses = {
            project: projectEntrance,
            tailings: tailingPathLength,
            plant: plantPathLength,
            rawStock: rawStockPathLength,
          };

          const status = !Object.values(statuses).some((value) => value === false);

          result.push({ statuses, status });
        }

        result.globalStatus = !result.some((result) => result.status === false) && result.length > 0
        return result;
      },
      reset(e){
        e.initData.loader.visible = true;
        e.$axios.delete('/api/v1/simulation/'+e.initData.user.selectedCourseId+'/'+e.myCompany.teamId)
            .then((response) => {
              let responseData = new Response().handle(response);
              e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType});

              if (responseData.status === true) {
                //EMPTY BLOCKS FOR ACTIONS
                e.simulationData.blocksForActions = [];
                //REFRESH LOCAL STORAGE
                localStorage.setItem('vMine_'+e.initData.user.selectedCourseId+'_blocksForActions', JSON.stringify(e.simulationData.blocksForActions));
                //DELETE LOCAL STORAGE
                localStorage.removeItem('vMine_'+e.initData.user.selectedCourseId+'_actualLevel');
                //RELOAD PAGE WITH ROUTER
                e.$router.go();
              }

            })
            .catch((error) => {
              e.initData.alerts.push(new Response().handle(error.response));
            })
            .finally(() => {
              e.initData.loader.visible = false;
            });
      }
    },
};
</script>

<style scoped>

</style>